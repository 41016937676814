@mixin provider-button {
  grid-area: button;
  display: grid;
  align-items: center;
  justify-items: center;
  background: $thunderbird;
}

.provider-wrapper {
  display: block;
  color: $thunderbird;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    color: $thunderbird;
  }
}

.provider {
  display: grid;
  border: 3px solid $thunderbird;
  height: 136px;
  border-radius: 10px;
  grid: "logo info button";
  grid-template-columns: 35% 3fr 2fr;
  grid-gap: 25px;

  &-link {
    display: none;
  }

  @media (max-width: 991px) {
    grid-gap: unset;
    grid-template-columns: 30% 3fr 2fr;
  }

  @media (max-width: 580px) {
    grid: "logo" "info" "button";
    grid-template-columns: unset;
    height: auto;
  }

  &__logo {
    display: grid;
    grid-area: logo;
    align-content: center;
    padding: 10px;

    img {
      width: 100%;
      object-fit: contain;

      @media (max-width: 580px) {
        height: 79px;
      }
    }
  }

  &__info {
    grid-area: info;
    display: grid;
    align-content: end;

    @media (max-width: 580px) {
      justify-content: center;
    }

    h4 {
      font-size: 17px;
      line-height: 20px;
      color: $thunderbird;

      @media (max-width: 991px) {
        font-size: 16px;
      }
    }

    ul {
      padding: 0;
      list-style: none;
    }

    li.mobile-info {
      @media (min-width: 581px) {
        display: none;
      }

      font-size: 12px;
      color: $thunderbird;
      text-align: center;
      margin: 10px;
    }

    li.desktop-info {
      display: flex;
      align-items: center;

      @media (max-width: 991px) {
        font-size: 14px;
      }

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 5.3C0 3.89435 0.558391 2.54628 1.55233 1.55233C2.54628 0.558391 3.89435 0 5.3 0C6.70565 0 8.05372 0.558391 9.04767 1.55233C10.0416 2.54628 10.6 3.89435 10.6 5.3C10.6 6.70565 10.0416 8.05372 9.04767 9.04767C8.05372 10.0416 6.70565 10.6 5.3 10.6C3.89435 10.6 2.54628 10.0416 1.55233 9.04767C0.558391 8.05372 0 6.70565 0 5.3H0ZM4.99755 7.5684L8.04893 3.75381L7.49773 3.31285L4.89579 6.56423L3.0528 5.02864L2.60053 5.57136L4.99755 7.56911V7.5684Z' fill='%23E61849'/%3E%3C/svg%3E%0A");
        content: "";
        width: 11px;
        height: 11px;
        padding-right: 15px;
        background-repeat: no-repeat;
      }
    }
  }

  &__button-mobile {
    @media (min-width: 581px) {
      display: none;
    }

    @include provider-button;

    .link {
      color: $white;
      font-weight: bold;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      padding: 15px;

      &::after {
        width: 9px;
        height: 18px;
        margin-left: 6px;
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='18' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.49331 8.71178e-05C1.68542 -0.00056518 1.87524 0.0418238 2.04881 0.124137C2.22239 0.206453 2.37531 0.326602 2.49634 0.475757L8.70746 8.18931C8.8966 8.41935 9 8.70789 9 9.00567C9 9.30344 8.8966 9.59198 8.70746 9.82202L2.27773 17.5356C2.05946 17.7981 1.7458 17.9632 1.40576 17.9946C1.06572 18.0259 0.72716 17.9209 0.464548 17.7027C0.201937 17.4845 0.03679 17.1709 0.00543891 16.831C-0.0259121 16.491 0.0791008 16.1526 0.297375 15.89L6.04556 8.99924L0.490269 2.10846C0.33302 1.91975 0.233131 1.68997 0.202424 1.44628C0.171715 1.2026 0.211473 0.955222 0.316992 0.733421C0.422512 0.511621 0.589376 0.324682 0.797842 0.194721C1.00631 0.0647595 1.24765 -0.00278155 1.49331 8.71178e-05Z' fill='white' /%3E%3C/svg%3E%0A");
      }
    }
  }

  &__button-desktop {
    @include provider-button;
    color: $white;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100%;

    @media (max-width: 991px) {
      font-size: 14px;
    }

    @media (max-width: 580px) {
      display: none;
    }

    .link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 33px;
      width: 80%;
      background: $white;
      color: $thunderbird;
      font-weight: bold;
      text-decoration: none;
      border-radius: 10px;

      &::after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='15' viewBox='0 0 9 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.20044 -5.10329e-07C2.35852 -0.000508806 2.51472 0.0324635 2.65754 0.0964923C2.80037 0.160521 2.92621 0.253979 3.0258 0.37L8.13666 6.37C8.2923 6.54893 8.37738 6.77337 8.37738 7.005C8.37738 7.23662 8.2923 7.46107 8.13666 7.64L2.84591 13.64C2.6663 13.8442 2.40821 13.9726 2.1284 13.997C1.8486 14.0214 1.57001 13.9397 1.35392 13.77C1.13783 13.6003 1.00193 13.3563 0.976136 13.0919C0.950339 12.8275 1.03675 12.5642 1.21636 12.36L5.94629 7L1.37508 1.64C1.24569 1.49321 1.16349 1.31447 1.13823 1.12493C1.11296 0.935377 1.14567 0.742952 1.2325 0.570424C1.31933 0.397896 1.45663 0.252486 1.62817 0.151395C1.79971 0.0503048 1.9983 -0.00223212 2.20044 -5.10329e-07Z' fill='%23E61849'/%3E%3C/svg%3E ");
        width: 10px;
        height: 15px;
        margin-left: 6px;
      }
    }

    span {
      text-align: center;
    }
  }
}
