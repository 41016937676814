.hamburger {
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 22;
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 15px 15px 15px 20px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;

  @media #{$sm} {
    display: none;
  }

  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;

    .hamburger-inner {
      position: relative;
      display: inline-block;
      width: 40px;
      height: 24px;
      background-color: $black;
      transition-duration: 75ms;
      position: absolute;
      width: 40px;
      height: 4px;
      top: 50%;
      display: block;
      margin-top: -2px;

      &::after,
      &::before {
        display: block;
        content: "";
        position: absolute;
        width: 40px;
        height: 4px;
        transition-timing-function: ease;
        transition-duration: .15s;
        transition-property: transform;
        border-radius: 4px;
        background-color: $black;
      }

      &::before {
        transition: top 75ms ease .12s,opacity 75ms ease;
        top: -10px;
      }

      &::after {
        transition: bottom 75ms ease .12s,transform 75ms cubic-bezier(.55,.055,.675,.19);
        bottom: -10px;
      }
    }
  }
}

#mobile-menu-toggle:checked + .page-wrapper {
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 0px;

  .hamburger {
    .hamburger-inner {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(45deg);
      background-color: lighten($natural-gray, 15%);

      &::before {
        top: 0;
        transition: top 75ms ease,opacity 75ms ease .12s;
        opacity: 0;
        background-color: lighten($natural-gray, 15%);
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease,transform 75ms cubic-bezier(.215,.61,.355,1) .12s;
        transform: rotate(-90deg);
        background-color: lighten($natural-gray, 15%);
      }
    }
  }

  .mobile-menu-wrapper {
    bottom: 0px;
    height: auto;
    background: $tuatara;
    overflow-y: scroll;
  }

  .branding {
    .logo {
      max-width: 170px;
      margin-top: 10px;

      .fill-black {
        fill: $white;
      }
    }
  }
}

.mobile-menu-wrapper {
  display: block;
  background: $white;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 18;
  padding-top: 0px;
  overflow: hidden;
  height: 84px;

  @media #{$sm} {
    height: 148px;
  }

  .disclaimer {
    font-family: $font-suisse-intl;
    font-size: 9px;
    line-height: 11px;
    color: #797673;
    padding: 0px 15px;

    @media #{$sm} {
      padding: 0px 50px;
    }
  }

  .main-menu {
    list-style: none;
    margin: 0px;
    padding: 0px 22px;
    font-family: "Suisse Intl";
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    font-size: 16px;
    line-height: 21px;
    display: block;

    .first-level {
      display: block;
      position: relative;
      border-bottom: 1px solid lighten($natural-gray, 8%);

      [class^="faz-"],
      [class*=" faz-"] {
        color: $white;
        position: absolute;
        top: 33px;
        left: 0px;
        font-size: 24px;
      }

      .caret {
        z-index: 2;
        position: absolute;
        display: block;
        height: 40px;
        width: 40px;
        top: 25px;
        right: 0px;

        &::after {
          top: 13px;
          right: 0px;
          display: block;
          content: "";
          height: 14px;
          width: 23px;
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg width='23' height='14' viewBox='0 0 23 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-6.57595e-07 2.14799L2.09897 5.71536e-07L11.5 9.68879L20.901 2.21526e-06L23 2.14799L11.5 14L-6.57595e-07 2.14799Z' fill='white'/%3E%3C/svg%3E%0A");
          transition: all 0.25s ease-in-out;
        }
      }

      .sub-menu {
        display: none;
        margin-left: -30px;
        padding-bottom: 10px;

        li {
          padding-bottom: 20px;
        }

        a {
          font-family: $font-suisse-intl;
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          color: darken($mercury, 20%);
          text-decoration: none;
        }
      }

      .trigger-checkbox:checked {
        & + .caret {
          &::after {
            transform: rotate(180deg);
          }

          & + .sub-menu {
            display: block;
          }
        }
      }

      & > a {
        position: relative;
        color: $white;
        text-decoration: none;
        text-transform: uppercase;
        display: block;
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
        z-index: 1;
        padding: 35px 35px 35px 35px;
      }
    }
  }
}
