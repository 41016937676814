.compare-container {
  padding-right: 0px;
  padding-left: 0px;
}

.content-wrapper {
  padding: 0px 50px;
}

.seo-block-wrapper {
  width: 100%;
}

.page-head {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 0;
  width: 100%;

  @media #{$sm} {
    margin-top: 16px;
    margin-bottom: 32px;
  }

  .sub-title {
    font-family: $font-sang-bleu-kingdom;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 29px;
    color: $violet;
    margin-bottom: 30px;

    @media (max-width: 640px) {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 15px;
    }
  }

  h1 {
    font-family: $font-sang-bleu-kingdom;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 44px;
    margin-bottom: 0px;

    @media (max-width: 640px) {
      font-size: 23px;
      line-height: 30px;
      margin-bottom: 8px;
    }
  }

  border-bottom: 1px solid $black;
}

h1 {
  font-family: $font-sang-bleu-kingdom;
  font-size: 36px;
  line-height: 53px;
  color: $violet;
}

h2 {
  font-family: $font-sang-bleu-kingdom;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: $violet;
  margin-bottom: 35px;
}

h3 {
  font-family: $font-sang-bleu-kingdom;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: $violet;
}

h4 {
  font-family: $font-sang-bleu-kingdom;
  font-weight: 600;
  font-size: 25px;
  line-height: 32px;
  color: $violet;
}

p {
  font-family: $font-sang-bleu-kingdom;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
}

.seo-text {
  margin-bottom: 24px;

  ol, ul, dl {
    font-family: $font-sang-bleu-kingdom;
    font-size: 16px;
    line-height: 21px;
    font-weight: 300;
  }
}

table {
  border-collapse: collapse;
  margin: 0px 0px 50px 0px;
  font-size: 16px;
  font-family: $font-sang-bleu-kingdom;
  width: 100%;

  thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    @media #{$md} {
      clip: auto;
      height: auto;
      margin: auto;
      overflow: visible;
      position: relative;
      width: auto;
    }

    tr {
      background-color: $violet;
      color: $white;
      display: block;
      margin-bottom: 5px;
      position: relative;

      @media #{$md} {
        border-bottom: none;
        display: table-row;
        margin-bottom: 0;
      }
    }
  }

  th,
  td {
    padding: 12px 15px;
    text-align: left;
  }

  td {
    border-bottom: 1px solid #dddddd;
    display: block;
    font-size: 16px;
    text-align: left;

    @media #{$md} {
      text-align: left;
      display: table-cell;
    }

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      width: 100%;

      @media #{$md} {
        display: none;
      }
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid $violet;

      @media #{$md} {
        border-bottom: 1px solid #dddddd;
      }

      &:first-child {
        border-top: 1px solid #dddddd;

        @media #{$md} {
          border-top: none
        }
      }

      &:nth-of-type(even) {
        background-color: $white;
      }

      &:last-of-type {
        border-bottom: 2px solid $violet;
      }

      td {
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;

        @media #{$md} {
          border-right: none;
        }

        &:first-child {
          border-left: 1px solid #dddddd;

          @media #{$md} {
            border-left: none;
          }
        }
      }
    }
  }
}
