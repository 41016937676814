.welcome-text {
  margin-bottom: 30px;

  @media #{$sm} {
    margin-bottom: 42px;
  }

  p, li {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    color: $violet;
    font-size: 16px;
    line-height: 21px;

    @media #{$sm} {
      font-size: 14px;
      line-height: 21px;
    }
  }

  .info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    line-height: 21px;
  }

  .arrow {
    cursor: pointer;
    display: inline-block;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-decoration-line: underline;
    color: $tuatara;
    margin-top: 0px;
    transition: all 0.25s ease-in-out;

    @media #{$sm} {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .hidden {
    display: none;
  }

  &-trigger {
    & + .info {
      height: 42px;

      & + .arrow {
        .open {
          display: inline;
        }

        .close {
          display: none;
        }
      }
    }

    &:checked {
      & + .info {
        -webkit-line-clamp: unset;
        height: auto;

        & + .arrow {
          .open {
            display: none;
          }
  
          .close {
            display: inline;
          }
        }
      }
    }
  }
}
