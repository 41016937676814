.violet-line {
  border-top: 1px solid $violet;
}

.porcelain-line {
  border-top: 2px solid $porcelain;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.trigger-checkbox {
  display: none;
}

.fill-black {
  fill: $black;
}

.hr-1-violet {
  border-top: 1px solid $violet;
}

.hr-1-brick-red {
  border-top: 1px solid $thunderbird;
}

.hr-1-porcelain {
  border-top: 1px solid $porcelain;
}

.hr-2-violet {
  border-top: 2px solid $violet;
}

.hr-2-brick-red {
  border-top: 2px solid $thunderbird;
}

.hr-2-porcelain {
  border-top: 2px solid $porcelain;
}

.hidden {
  display: none;
}

.hide-on-sm {
  display: none;

  @media #{$sm} {
    display: inline;
  }
}
