.modular-text-block {
  margin-bottom: 40px;
}

.modular-partners-grid {
  padding: 0;
  margin-bottom: 40px;

  .container {
    margin-bottom: -30px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.modular-inter-link-cloud {
  margin-bottom: 40px;

  .container {
    margin-bottom: -15px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.modular-faq-container {
  margin-bottom: 20px;
}

.modular-seo-blocks-container {
  margin-bottom: 10px;
}

.modular-break {
  margin: 0;
  margin-bottom: 40px;
}
