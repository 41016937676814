.seo-block-wrapper {
  .entry-trigger {
    display: none;

    &:checked + .entry {
      .head {
        width: 100%;
        position: relative;

        &::after {
          transform: rotate(90deg);
        }
      }

      .content {
        display: block;
      }
    }
  }

  .entry {
    background: $porcelain;
    padding-right: 20px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 45px;
    }

    @media #{$sm} {
      padding-right: 30px;
    }

    .trigger {
      display: flex;
      cursor: pointer;
    }

    .icon {
      display: inline-flex;
      height: 100%;
      width: 100%;
      max-width: 64px;
      max-height: 64px;
      background: $violet;
      color: $white;
      vertical-align: middle;
      margin-right: 16px;

      [class^="faz-"],
      [class*=" faz-"] {
        font-size: 40px;
        padding: 12px;
      }

      @media #{$sm} {
        max-width: 94px;
        max-height: 94px;
        margin-right: 28px;

        [class^="faz-"],
        [class*=" faz-"] {
          font-size: 48px;
          padding: 24px;
        }
      }
    }

    .head {
      width: 100%;
      position: relative;

      &::after {
        display: block;
        position: absolute;
        content: " ";
        right: 10px;
        top: 17px;
        content: " ";
        width: 17px;
        height: 30px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='30' viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.79963 29.5265L0.311837 26.8947L11.5334 15.1073L0.311838 3.31989L2.79963 0.68811L16.5266 15.1073L2.79963 29.5265Z' fill='%23110D35'/%3E%3C/svg%3E%0A");
        transition: all 0.25s ease-in-out;

        @media #{$sm} {
          top: 35px;
        }
      }

      .header,
      .description {
        display: block;
        width: 100%;
      }

      .header {
        font-family: $font-sang-bleu-kingdom;
        margin-top: 0px;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: $violet;
        min-height: 64px;
        margin-bottom: 0px;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        padding-right: 40px;

        @media #{$sm} {
          min-height: initial;
          font-weight: 600;
          font-size: 20px;
          line-height: 29px;
          margin-top: 35px;
          margin-bottom: 0.5rem;
        }
      }

      .description {
        margin-top: 18px;
        font-family: $font-sang-bleu-kingdom;
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        color: $violet;
        margin-left: -80px;
        width: calc(100% + 80px);
        padding-right: 0px;

        @media #{$sm} {
          padding-right: 40px;
          margin-left: 0px;
          width: 100%;
        }
      }
    }

    .content {
      display: none;
      font-family: $font-sang-bleu-kingdom;
      font-size: 16px;
      font-weight: 300;
      line-height: 21px;
      color: $violet;
      margin-left: 0px;
      margin-top: 15px;
      margin-bottom: 5px;
      padding-bottom: 10px;
      margin-left: 20px;
      padding-right: 0px;

      @media #{$sm} {
        padding-right: 52px;
        margin-top: 0px;
        margin-left: 122px;
      }
    }
  }
}
