.nav-grid {
  margin-bottom: 43px;

  .container {
    padding-left: 0px;
    padding-right: 0px;

    @media #{$sm} {
      padding-left: -5px;
      padding-right: -5px;
    }
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }

  .item {
    background: $porcelain;
    display: block;
    margin-bottom: 20px;
    min-height: 280px;
    overflow: hidden;
    text-decoration: none;
    transition: all 0.35s ease-in-out;

    .head  {
      display: block;
      height: 188px;
      position: relative;

      @media #{$sm} {
        height: 130px;
      }
    }

    .thumbnail {
      height: 188px;
      overflow: hidden;
      position: relative;
      text-align: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      @media #{$sm} {
        height: 130px;
      }

      .img,
      picture {
        width: 100%;
        height: auto;
      }
    }

    .title {
      font-size: 14px;
      line-height: 18px;
      bottom: 52px;
      font-family: $font-suisse-intl;
      font-weight: 400;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin-bottom: -5px;

      @media #{$sm} {
        font-size: 11px;
        line-height: 14px;
      }
    }

    .header {
      font-size: 18px;
      line-height: 26px;
      font-family: $font-sang-bleu-kingdom;
      font-weight: bold;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin-bottom: 0px;
      margin-top: -1px;
    }

    .action {
      margin-top: 18px;
      text-align: right;
    }

    .link {
      background: $thunderbird;
      height: 28px;
      display: inline-flex;
      background: #E61849;
      color: $white;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 6px;
      padding: 10px;

      span::after {
        content: " ";
        height: 8px;
        width: 6px;
        background-repeat: no-repeat;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.920566 8L8.48302e-08 7.26992L4.15234 4L8.51902e-09 0.730077L0.920566 6.19017e-08L6 4L0.920566 8Z' fill='white'/%3E%3C/svg%3E%0A");
        margin-left: 20px;
      }
    }

    .caption {
      color: $violet;
      display: block;
      font-family: $font-suisse-intl;
      font-style: normal;
      font-weight: normal;
      padding: 9px 10px 10px;
      text-align: left;

      .desc {
        display: block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-size: 16px;
        line-height: 21px;
        height: 42px;
        margin-top: -6px;

        @media #{$sm} {
          font-size: 14px;
          line-height: 18px;
          height: 54px;
          -webkit-line-clamp: 3;
        }
      }
    }

    &:hover {
      .link {
        animation: linkMove 0.5s ease-in-out infinite;
      }
    }
  }

  .col-lg-4,
  .col-ms-6 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@keyframes linkMove {
  0% {
    right: 15px;
  }

  30% {
    right: 10px;
  }

  100% {
    right: 15px;
  }
}
