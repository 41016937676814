.desktop-menu-wrapper {
  position: absolute;
  height: 30px;
  left: 0px;
  right: 0px;
  top: 148px;
  background: #E9EDEE;
  display: none;

  @media #{$sm} {
    display: block;
  }

  .main-menu {
    list-style: none;
    margin: 0px -20px;
    padding: 0px 5px;
    font-family: "Suisse Intl";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: none;
    height: 30px;
    width: calc(100% + 40px);

    @media #{$sm} {
      display: block;
    }

    .first-level {
      display: inline-block;
      position: relative;
      margin: 0px 12px;

      @media #{$md} {
        margin: 0px 26px;
      }

      &:first-child {
        margin-left: 0px;
      }

      & > a {
        position: relative;
        color: $violet;
        text-decoration: none;
        display: block;
        padding: 6px 15px;
        margin: 0px;
        z-index: 1;
        font-weight: 700;
        height: 30px;

        &::before {
          content: attr(data-label);
          display: block;
          text-align: center;
          background: #E9EDEE;
          color: $violet;
          font-weight: 500;
          z-index: 3;
          padding: 6px 0px;
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          height: 30px;
          pointer-events: none;
        }
      }

      &:hover > a {
        color: $violet;
        font-weight: 700;
        z-index: 2;

        &::before {
          display: none;
        }
      }

      &.has-sub-menu:hover {
        .sub-menu {
          display: block;
        }
      }
    }

    .sub-menu {
      left: 0px;
      top: 30px;
      position: absolute;
      width: 188px;
      list-style: none;
      margin: 0;
      padding: 0;
      display: none;
      background: $white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.13);
      padding: 10px 6px 10px 0px;
      z-index: 10;

      .second-level {
        display: block;
        position: relative;

        a {
          width: 100%;
          display: block;
          min-height: 28px;
          background: $white;
          padding: 4px 4px 4px 9px;
          text-decoration: none;
          transition: all 0.25s ease-in-out;
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          color: $violet;

          &:hover {
            background: $porcelain;
          }
        }
      }
    }
  }
}
