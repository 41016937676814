.inter-link-cloud {
  margin-bottom: 50px;

  .container {
    padding: 0;
  }

  h2 {
    font-family: $font-sang-bleu-kingdom;
    font-weight: 700;
    color: $violet;
    padding-bottom: 10px;
  }

  h3 {
    font-family: $font-sang-bleu-kingdom;
    font-weight: 600;
    color: $violet;
    padding-bottom: 10px;
  }

  .row + h3 {
    padding-top: 15px;
  }

  .introduction {
    font-family: $font-sang-bleu-kingdom;
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    color: $violet;
    padding-bottom: 8px;
  }

  .links {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0px;
    text-align: left;
    width: 100%;

    @media #{$md} {
      padding: 0 5px;
    }

    li {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;

      @media #{$md} {
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 10px;
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 28px;
        border: 1px solid $thunderbird;
        box-sizing: border-box;
        font-family: $font-sang-bleu-kingdom;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.15em;
        color: $thunderbird;
        text-decoration: none;
        padding: 5px 10px;
        transition: all 0.5s ease;

        @media #{$md} {
          font-size: 13px;
          line-height: 15px;
        }

        &:hover {
          background: $thunderbird;
          color: $white;
        }
      }
    }
  }
}
