@font-face {
  font-family: 'faz-icons';
  src:  url('./../../assets/icons/faz-icons.eot?afvlly');
  src:  url('./../../assets/icons/faz-icons.eot?afvlly#iefix') format('embedded-opentype'),
    url('./../../assets/icons/faz-icons.ttf?afvlly') format('truetype'),
    url('./../../assets/icons/faz-icons.woff?afvlly') format('woff'),
    url('./../../assets/icons/faz-icons.svg?afvlly#faz-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='faz-'], [class*=' faz-'] {
  font-family: 'faz-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.faz-icon-phone-contract-with-phone:before {
  content: '\e926';
}

.faz-icon-cable-internet-comparison:before {
  content: '\e900';
}

.faz-icon-calculator:before {
  content: '\e901';
}

.faz-icon-caret-down:before {
  content: '\e902';
}

.faz-icon-caret-left:before {
  content: '\e903';
}

.faz-icon-caret-right:before {
  content: '\e904';
}

.faz-icon-caret-up:before {
  content: '\e905';
}

.faz-icon-cheap-electricity-supplier:before {
  content: '\e906';
}

.faz-icon-commercial-electricity-price-comparison:before {
  content: '\e907';
}

.faz-icon-dsl-compare:before {
  content: '\e908';
}

.faz-icon-dsl-offers:before {
  content: '\e909';
}

.faz-icon-dsl-provider-compare:before {
  content: '\e90a';
}

.faz-icon-electricity-comparison:before {
  content: '\e90b';
}

.faz-icon-electricity-money:before {
  content: '\e90c';
}

.faz-icon-electricity-provider:before {
  content: '\e90d';
}

.faz-icon-electricity-provider-comparison:before {
  content: '\e90e';
}

.faz-icon-euro:before {
  content: '\e90f';
}

.faz-icon-expand-down:before {
  content: '\e910';
}

.faz-icon-faq:before {
  content: '\e911';
}

.faz-icon-faz:before {
  content: '\e912';
}

.faz-icon-faz-home:before {
  content: '\e913';
}

.faz-icon-gas:before {
  content: '\e914';
}

.faz-icon-gas-calculate-consumption:before {
  content: '\e915';
}

.faz-icon-gas-compare:before {
  content: '\e916';
}

.faz-icon-gas-price-compare:before {
  content: '\e917';
}

.faz-icon-gas-provider-compare:before {
  content: '\e918';
}

.faz-icon-gas-tariff-compare:before {
  content: '\e919';
}

.faz-icon-green-electricity-comparison:before {
  content: '\e91a';
}

.faz-icon-home:before {
  content: '\e91b';
}

.faz-icon-internet-connection-comparison:before {
  content: '\e91c';
}

.faz-icon-laptop:before {
  content: '\e91d';
}

.faz-icon-mobile-phone-with-contract:before {
  content: '\e91e';
}

.faz-icon-network:before {
  content: '\e91f';
}

.faz-icon-phone:before {
  content: '\e920';
}

.faz-icon-phone-contract-comparison:before {
  content: '\e921';
}

.faz-icon-phone-contract-without-phone:before {
  content: '\e922';
}

.faz-icon-phone-tariff-comparison:before {
  content: '\e923';
}

.faz-icon-settings:before {
  content: '\e924';
}

.faz-icon-tariff-comparison:before {
  content: '\e925';
}
