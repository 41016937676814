.mini-form-wrapper {
  max-width: $container-width;
  margin: auto;
  background: $white;
  box-shadow: 1px 1px 10px rgba($black, 0.25);
  padding: 15px;
  margin: -10px auto;

  @media #{$lg} {
    margin: 0px auto;
    padding: 15px 15px 0px;
  }
}

.mini-form-section {
  padding: 23px 10px;
  max-width: 100%;
  width: 100%;
  background: $thunderbird;
  margin-bottom: 50px;

  @media #{$sm} {
    padding: 23px 50px;
  }
}
