.breadcrumb-desktop {
  display: none;
  @media #{$md} {
    display: block;
  }
}
.breadcrumb-mobile {
  display: block;
  margin-bottom: 40px;
  font-weight: bolder;
  @media #{$md} {
    display: none;
  }
}
.breadcrumbs-wrapper {
  .container {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }

  .breadcrumbs {
    margin: 32px 0px 0px;
    padding: 0;
    display: flex;
    list-style: none;
    list-style-type: none;
    font-weight: 500;

    .item {
      color: $violet;
      font-size: 16px;
      line-height: 21px;
      position: relative;
      padding-right: 8px;

      @media #{$sm} {
        font-size: 12px;
        line-height: 14px;
      }

      &:not(:last-child)::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 6px;
        height: 8px;
        top: 12px;
        right: 0px;
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.920566 8L8.48302e-08 7.26992L4.15234 4L8.51902e-09 0.730077L0.920566 6.19017e-08L6 4L0.920566 8Z' fill='%23110D35'/%3E%3C/svg%3E%0A");

        @media #{$sm} {
          top: 8px;
        }
      }

      a {
        display: block;
        padding: 5px;
        color: $violet;
        text-decoration: none;

        &:hover {
          color: $thunderbird;
        }
      }
    }

    .home {
      a {
        padding-left: 0;
      }
    }
  }
}
