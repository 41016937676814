.footer-wrapper {
  background-color: $violet;
  text-align: center;

  .logo {
    margin-top: 30px;
    margin-left: 40px;
    margin-right: 40px;

    a {
      text-decoration: none;
    }
  }

  .categories {
    text-align: center;

    .categories-menu {
      list-style: none;
      margin: 0px 30px 0px;
      padding: 0;
      width: auto;
      max-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      display: block;
      border-top: none;
      border-bottom: 1px solid $white;

      @media #{$md} {
        max-width: calc(100% - 100px);
        border-top: 1px solid $white;
        border-bottom: none;
        margin: 20px auto 0px;
        padding-bottom: 0px;
      }

      li {
        padding: 5px 11px;
        margin-top: 14px;
        margin-bottom: 10px;
        display: inline-block;

        @media #{$md} {
          padding: 0px 11px;
        }

        @media #{$max-sm} {
          min-width: 100%;
          flex-direction: column;
          flex-basis: 100%;
          flex: 1;
          margin-top: 9px;
          margin-bottom: 0px;
          display: block;
          
          &:not(:first-child) {
            margin-top: -1px;
          }

          &:last-child {
            margin-bottom: 4px;
          }
        }

        a {
          display: inline-block;
          font-family: $font-suisse-intl;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          text-transform: uppercase;
          color: $white;
          text-decoration: none;
          border-bottom: 2px solid transparent;
          transition: border 0.25s ease-in-out;

          @media #{$md} {
            font-size: 11px;
            line-height: 13px;
          }

          &:hover,
          &:focus {
            outline: none;
            color: $white;
            border-bottom: 2px solid $thunderbird;
          }
        }
      }
    }
  }

  .about {
    margin: 0px 30px;

    @media #{$md} {
      margin: 0px 40px;
    }

    .about-menu {
      display: block;
      list-style: none;
      margin: 0 auto;
      padding: 0;
      padding-bottom: 6px;
      justify-content: center;
      max-width: 712px;
      border-bottom: 1px solid $white;

      @media #{$md} {
        display: flex;
        border-bottom: none;
        margin: 1px auto 0px;
        padding: 0;
      }

      li {
        display: block;
        padding: 5px 10px;
        width: 100%;
        text-align: center;

        @media #{$md} {
          padding: 0px 10px;
          display: inline-flex;
        }

        a,
        .consent-button {
          font-family: $font-suisse-intl;
          font-style: normal;
          font-weight: bold;
          display: inline-block;
          padding: 7px 0px 1px;
          margin: 0 auto;
          margin-bottom: 0px;
          text-decoration: none;
          color: $white;
          border: none;
          border-bottom: 2px solid transparent;
          transition: color 0.25s ease-in-out, border 0.25s ease-in-out;
          background: transparent;
          font-size: 16px;
          line-height: 18px;

          @media #{$md} {
            font-size: 11px;
            line-height: 14px;
            margin-bottom: 15px;
          }

          &:hover,
          &:focus {
            outline: none;
            color: $white;
            border-bottom: 2px solid $thunderbird;
          }
        }
      }
    }
  }

  .copyright {
    font-family: $font-suisse-intl;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: $white;

    @media #{$md} {
      font-size: 11px;
      line-height: 13px;
    }

    .text {
      display: inline-block;
      max-width: 100%;
      margin: 17px 10px 28px;

      @media #{$md} {
        max-width: 220px;
        margin: 24px 10px 24px;
      }
    }
  }
}
