.header-wrapper {
  padding: 84px 0px 0px;

  .disclaimer-part-2 {
    padding-right: 2px;
    display: inline-block;
  }

  @media #{$sm} {
    padding: 178px 50px 0px;
  }

  .container {
    width: 100%;
    padding: 0px;

    @media #{$sm} {
      padding: 0px 50px;
    }

    @media #{$xl} {
      padding-right: 0px;
      padding-left: 0px;
    }

    .text-right {
      text-align: center;

      @media #{$sm} {
        text-align: right;
      }
    }
  }

  .branding {
    position: relative;
    text-align: center;
    z-index: 21;

    .branding-desktop {
      display: none;
  
      @media #{$sm} {
        display: block;
      }
    }
  
    .branding-mobile {
      display: block;
  
      @media #{$sm} {
        display: none;
      }
    }

    .logo {
      margin: 10px 0px 14px;
      max-width: 170px;
      display: inline-block;

      @media #{$sm} {
        margin: 38px 0px 5px;
        max-width: 356px;
      }
    }
  }
}
