@font-face {
  font-family: 'Suisse Intl';
  src: url('./../../assets/fonts/SuisseIntl-Regular-WebM.eot');
  src: url("./../../assets/fonts/SuisseIntl-Regular-WebM.woff") format("woff"),
  url("./../../assets/fonts/SuisseIntl-Regular-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SuisseIntl-Regular-WebM.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./../../assets/fonts/SuisseIntl-SemiBold-WebM.eot');
  src: url("./../../assets/fonts/SuisseIntl-SemiBold-WebM.woff") format("woff"),
  url("./../../assets/fonts/SuisseIntl-SemiBold-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SuisseIntl-SemiBold-WebM.svg") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('./../../assets/fonts/SuisseIntl-Bold-WebM.eot');
  src: url("./../../assets/fonts/SuisseIntl-Bold-WebM.woff") format("woff"),
  url("./../../assets/fonts/SuisseIntl-Bold-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SuisseIntl-Bold-WebM.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Kingdom';
  src: url('./../../assets/fonts/SangBleuKingdom-Regular-WebM.eot');
  src: url("./../../assets/fonts/SangBleuKingdom-Regular-WebM.woff") format("woff"),
  url("./../../assets/fonts/SangBleuKingdom-Regular-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SangBleuKingdom-Regular-WebM.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Kingdom';
  src: url('./../../assets/fonts/SangBleuKingdom-Medium-WebM.eot');
  src: url("./../../assets/fonts/SangBleuKingdom-Medium-WebM.woff") format("woff"),
  url("./../../assets/fonts/SangBleuKingdom-Medium-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SangBleuKingdom-Medium-WebM.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SangBleu Kingdom';
  src: url('./../../assets/fonts/SangBleuKingdom-Bold-WebM.eot');
  src: url("./../../assets/fonts/SangBleuKingdom-Bold-WebM.woff") format("woff"),
  url("./../../assets/fonts/SangBleuKingdom-Bold-WebM.woff2") format("woff2"),
  url("./../../assets/fonts/SangBleuKingdom-Bold-WebM.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
