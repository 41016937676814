.faz-menu {
  position: absolute;
  top: 0px;
  left: 0px;
  color: $violet;
  font-family: $font-suisse-intl;
  z-index: 21;
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 5px;
  width: calc(100% - 100px);
  display: none;

  @media #{$sm} {
    display: block;
  }

  .link {
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: $violet;
    display: inline-block;
    padding: 10px;
    min-height: 36px;
    min-height: 16px;

    [class^="faz-"],
    [class*=" faz-"] {
      position: absolute;
      left: 5px;
      top: 12px;
      transition: color 0.25s ease-in-out;
    }

    .faz-icon-home {
      display: inline-block;
      height: 13px;
      width: 16px;
    }

    .text {
      font-size: 10px;
      font-family: $font-suisse-intl;
      color: $violet;
      padding-left: 16px;
      transition: color 0.25s ease-in-out;
    }

    &.home {

      .text {
        max-width: 11px;
        max-height: 13px;
        padding: 0;
        display: inline-block;
        overflow: hidden;
      }
    }

    &:hover {
      [class^="faz-"],
      [class*=" faz-"],
      .text {
        color: $thunderbird;
      }
    }
  }

  .menu-item {
    display: inline-block;
    position: relative;

    &.services {
      position: absolute;
      right: -10px;
    }

    .trigger-checkbox:checked {
      & + .trigger + .dropdown {
        display: block;
      }
    }

    @media #{$lg} {  
      .trigger-checkbox:hover {
        & + .trigger + .dropdown {
          display: block;
        }
      }
    }
  }

  .dropdown {
    display: none;
    left: calc(50% + .625rem);
    top: 27px;
    padding-top: .9375rem;
    position: absolute;
    transform: translateX(-50%);
    width: 9.375rem;
    z-index: 21;

    @media #{$lg} {
      &:hover {
        display: block;
      }
    }

    .products-list {
      background: $white;
      border: .0625rem solid #ddd;
      border-radius: .125rem;
      list-style: none;
      padding: .625rem 1.25rem .3125rem;
      position: relative;

      &::after,
      &::before {
        border-left: solid transparent;
        border-bottom: solid transparent;
        border-right: solid transparent;
        border-color: transparent transparent #ddd;
        border-bottom-color: rgb(221, 221, 221);
        border-style: solid;
        border-width: medium .4375rem .625rem;
        bottom: 100%;
        content: " ";
        height: 0;
        left: 50%;
        margin-left: -.4375rem;
        pointer-events: none;
        position: absolute;
        width: 0;
      }

      &::after {
        border-bottom-color: $white;
        bottom: calc(100% - .125rem);
      }

      .item {
        height: 1.125rem;
        margin: .625rem 0;
      }

      .item-link {
        font-weight: 600;
        color: #111;
        font-size: .875rem;
        text-decoration: none;
        transition: color .1s ease-in-out, background-image .1s ease-in-out, transform .1s ease-in-out;
        background-color: transparent;
        outline: none;
        font-family: $font-suisse-intl;
        font-size: 13px;

        &:hover {
          color: $thunderbird;
        }
      }
    }
  }
}
