.faq {
  &-header {
    position: relative;
    padding-left: 50px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    font-family: $font-sang-bleu-kingdom;
    margin-bottom: 25px;

    &::before,
    &::after {
      display: block;
      position: absolute;
      content: " ";
    }

    &::before {
      top: -4px;
      left: 0px;
      width: 38px;
      height: 38px;
      background-color: $violet;
      border-radius: 100%;
    }

    &::after {
      top: 4px;
      left: 8px;
      height: 22px;
      width: 22px;
      font-size: 22px;
      display: inline-block;
      content: "\e911";
      font-family: 'faz-icons' !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: $white;
    }

    @media #{$sm} {
      padding-left: 50px;
      font-weight: 600;
      font-size: 20px;
      line-height: 29px;

      &::before {
        left: 0px;
      }

      &::after {
        top: 0px;
        left: 8px;
      }
    }
  }

  &-entry-trigger {
    display: none;

    &:checked + .question {

      &::after {
        transform: rotate(90deg);
      }

      & + .answer {
        display: block;
      }
    }

    &:not(:checked) + .question {
      & + .answer {
        display: none;

        p:first-child {
          text-decoration: none;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 30px);
          max-width: 380px;
        }
      }
    }
  }

  &-entry {
    background: $white;
    padding: 10px 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    border: 1px solid $violet;

    &:last-child {
      margin-bottom: 45px;
    }

    @media #{$sm} {
      padding: 15px 30px 15px 52px;
    }

    .question {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      justify-content: center;
      min-height: 30px;
      min-width: 100%;
      position: relative;
      padding-right: 38px;
      cursor: pointer;

      h3 {
        color: $violet;
        font-family: $font-sang-bleu-kingdom;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding: 0px;
        margin: 0px;
      }

      &::after {
        display: inline-block;
        position: absolute;
        right: 10px;
        top: calc(50% -15px);
        content: " ";
        width: 17px;
        height: 30px;
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='30' viewBox='0 0 17 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.79963 29.5265L0.311837 26.8947L11.5334 15.1073L0.311838 3.31989L2.79963 0.68811L16.5266 15.1073L2.79963 29.5265Z' fill='%23110D35'/%3E%3C/svg%3E%0A");
        transition: all 0.25s ease-in-out;
      }

      @media #{$sm} {
        padding-right: 28px;

        &::after {
          right: 10px;
        }
      }
    }

    .answer {
      display: block;
      padding-top: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      font-family: $font-sang-bleu-kingdom;
      font-weight: 300;
      padding-right: 0px;

      @media #{$sm} {
        padding-right: 88px;
      }

      ul,
      ol {
        li {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0px;
          }
        }
      }

      p:last-child,
      ul:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
