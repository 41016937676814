.check24-credit-mini {
  background: $thunderbird;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
  padding: 15px 22px;
  margin: 0px 0px 40px;
  border-radius: 0px 20px;

  @media (max-width: 928px) {
    padding-bottom: 15px 22px 0px;
  }

  @media (max-width: 640px) {
    padding: 10px 10px 0px;
  }

  input[type="text"],
  select[type="text"] {
    background-color: $white;
    width: 100%;
    border: 1px solid $porcelain;
    font-family: $font-suisse-intl;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $violet;
    padding: 12px 11px;
  }

  span.c24kredit-amount,
  span.c24kredit-duration,
  span.c24kredit-purpose {
    font-family: $font-suisse-intl;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: $white;
    margin-bottom: 8px;
    display: inline-block;
    width: 100%;
  }

  .c24kredit-title {
    display: none;
  }

  .c24kredit-submit {
    display: inline-block;
    height: 77px;
    background: lighten($violet, 5);
    border-radius: 0px 15px;
    font-family: $font-suisse-intl;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: $white;
    border: none;
    position: relative;
    padding-right: 60px;
    min-width: 250px;
    max-width: 100%;
    color: transparent;
    width: 100%;

    &::before {
      display: inline-block;
      content: "Jetz\00a0vergleichen";
      font-family: $font-suisse-intl;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 77px;
      color: $white;
      width: 100%;
      padding-right: 85px;
      padding-left: 25px;
      text-align: center;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    &::after{
      content: " ";
      background-color: $violet;
      border-top-right-radius: 15px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='27' viewBox='0 0 15 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.04284 26.7012C1.56637 26.7021 1.10461 26.5493 0.737719 26.2693C0.531228 26.1116 0.360539 25.918 0.235427 25.6995C0.110315 25.481 0.0332384 25.2419 0.00861263 24.9959C-0.0160131 24.7499 0.0122958 24.5019 0.0919174 24.266C0.171539 24.0301 0.300907 23.811 0.472616 23.6212L9.60847 13.5549L0.798898 3.46977C0.629506 3.27767 0.503007 3.05663 0.426675 2.81935C0.350342 2.58208 0.32568 2.33326 0.354105 2.08718C0.382531 1.8411 0.463482 1.60262 0.59231 1.38545C0.721137 1.16828 0.8953 0.976699 1.10479 0.821718C1.31578 0.650747 1.56287 0.521786 1.83054 0.442928C2.09822 0.364071 2.38071 0.33702 2.66027 0.363472C2.93984 0.389924 3.21045 0.469309 3.45513 0.596644C3.6998 0.723979 3.91326 0.896515 4.08209 1.10342L13.9317 12.3717C14.2316 12.7078 14.3956 13.1293 14.3956 13.5643C14.3956 13.9993 14.2316 14.4208 13.9317 14.7568L3.73542 26.0251C3.53085 26.2524 3.27098 26.4321 2.9769 26.5495C2.68282 26.667 2.36281 26.719 2.04284 26.7012Z' fill='white'/%3E%3C/svg%3E%0A");
      background-position-y: center;
      background-position-x: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      right: 0px;
      display: inline-block;
      width: 60px;
      height: 100%;
    }
  }

  .c24kredit-full,
  .c24kredit-left,
  .c24kredit-right {
    padding-right: 10px;
    padding-left: 10px;
    width: 25%;

    @media (max-width: 928px) {
      width: 50%;
      margin-bottom: 15px;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .c24kredit-title + .c24kredit-full {
    width: 20%;

    @media (max-width: 928px) {
      width: 50%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .c24kredit-title + .c24kredit-full + .c24kredit-left {
    @media (max-width: 1020px) {
      width: 20%;
    }

    @media (max-width: 928px) {
      width: 50%;
    }

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  .c24pp1 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;

    .c24kredit-left:first-child {
      display: none;
    }

    .c24kredit-full:last-child {
      width: 30%;

      @media (max-width: 1020px) {
        width: 35%;
      }

      @media (max-width: 928px) {
        width: 50%;
      }

      @media (max-width: 640px) {
        width: 100%;
      }
    }
  }
}
