.mini-iframe-wrapper {
  display: flex;
  background: #E8EDEE;
  // max-width: 712px;
  margin: 0 auto 40px;
  flex-wrap: wrap;

  &.container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .list-thumbnail {
    width: 100%;
    max-width: 218px;
    height: 146px;
    display: block;

    @media #{$max-md} {
      flex: 0 0 100%;
      display: block;
      max-width: 100%;
    }

    .thumbnail {
      height: 146px;
      overflow: hidden;
      position: relative;
      text-align: center;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;

      .img,
      picture {
        width: 100%;
        height: auto;
      }
    }
  }

  .list-text {
    width: 100%;
    padding: 16px 0px 16px 18px;
    max-width: calc(100% - 423px);

    @media #{$max-md} {
      padding: 20px 18px 7px 18px;
      max-width: calc(100% - 205px);
    }

    @media #{$max-sm} {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .sub-header h2 {
      font-family: $font-sang-bleu-kingdom;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      padding: 0;
      margin: 0;
      margin-bottom: 12px;

      @media #{$sm} {
        margin-bottom: 0px;
      }
    }

    ul {
      font-family: $font-suisse-intl;
      font-style: normal;
      font-weight: 400;
      padding-left: 15px;
      margin-top: 5px;
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 21px;

      @media #{$sm} {
        font-size: 14px;
        line-height: 18px;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 10px;

          @media #{$sm} {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  .list-button {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    text-align: center;
    align-items: center;

    @media #{$max-md} {
      justify-content: right;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .button {
      display: inline-flex;
      width: 185px;
      height: 32px;
      background: #E61849;
      color: $white;
      text-decoration: none;
      align-items: center;
      justify-content: center;

      span {
        &::after {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.07399 11L1.25087e-07 9.99614L4.8444 5.5L1.25618e-08 1.00386L1.07399 6.73424e-08L7 5.5L1.07399 11Z' fill='white'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          display: inline-block;
          width: 7px;
          height: 11px;
          margin-left: 20px;
        }
      }
    }
  }
}
