.partners-grid {
  background: $white;
  padding-top: 60px;
  margin-bottom: 60px;

  .row {
    margin-right: -17.5px;
    margin-left: -17.5px;

    @media #{$max-sm} {
      margin-right: -10px;
      margin-left: -10px;
    }

    .col-lg-4 {
      padding-right: 17.5px;
      padding-left: 17.5px;

      @media #{$max-sm} {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }

  h2 {
    font-family: $font-suisse-intl;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: $violet;
    padding-bottom: 25px;
    margin-top: -2px;
  }

  .text-center {
    justify-content: center;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    text-decoration: none;
    background: $white;
    text-align: center;
    height: 58px;
    margin-bottom: 30px;
    transition: all 0.5s ease;
    border: 1px solid $violet;

    &:hover {
      background: darken($white, 5);
    }

    img {
      max-height: 34px;
      max-width: 100%;
    }
  }

  &__more {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 268px;
    height: 44px;
    background: $thunderbird;
    border-radius: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    color: $white;
    text-decoration: none;
    transition: background-color 0.5s ease;
    margin-top: 20px;
    margin-bottom: 45px;

    &:hover {
      color: $white;
      background: darken($thunderbird, 10);
    }
  }
}
