$violet: #110D35;
$porcelain: #E8EDEE;
$natural-gray: #8B8885;
$tuatara: #333332;
$mercury: #E5E5E5;
$white: #FFFFFF;
$black: #000000;
$thunderbird: #B62519;
$seashell: #F1F1F1;

$box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);

$font-suisse-intl: 'Suisse Intl', sans-serif;
$font-sang-bleu-kingdom: 'SangBleu Kingdom', "Times New Roman", serif;

$container-width: 712px;
$sm: (min-width: 576px);
$md: (min-width: 768px);
$lg: (min-width: 992px);
$xl: (min-width: 1200px);

$max-sm: (max-width: 575px);
$max-md: (max-width: 767px);
$max-lg: (max-width: 991px);
$max-xl: (max-width: 1199px);
