@import './conf/index';
@import './partials/bootstrap-reboot';
@import './partials/helpers';
@import './partials/grid';
@import './partials/header';
@import './partials/mobile-menu';
@import './partials/desktop-menu';
@import './partials/breadcrumbs';
@import './partials/content';
@import './partials/nav-grid';
@import './partials/faq';
@import './partials/seo-block';
@import './partials/footer';
@import './partials/fonts';
@import './partials/font-icons';
@import './partials/faz-menu';
@import './partials/mini-iframe';
@import './partials/mini-form';
@import './partials/form-inputs';
@import './partials/provider-integration';
@import './partials/check24-mini-form';
@import './partials/inter-link-cloud';
@import './partials/partners-grid';
@import './partials/modular-content';
@import './partials/welcome-text';

html,
body {
  padding: 0px;
  margin: 0px;
}

body {
  background-color: $mercury;
  text-rendering: optimizeLegibility;
  color: $violet;
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  height: 100vh;
  font-family: $font-suisse-intl;
}

ol,
ul,
dl,
h6,
h5,
h4,
h3,
h2,
h1,
p {
  display: block;
  width: 100%;
}

.seo-text,
.faq-wrapper,
.seo-block-wrapper {
  ol,
  ul,
  dl {
    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &::marker {
        font-weight: bold;
      }
    }
  }
}

.page-wrapper {
  background-color: $white;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .header-wrapper,
  .footer-wrapper,
  .breadcrumbs-wrapper,
  .desktop-menu-wrapper,
  .check24-mini-form-wrapper {
    flex: none;
  }

  .breadcrumbs-wrapper {
    padding: 0 20px;

    @media #{$sm} {
      padding: 0 50px;
    }
  }

  .check24-mini-form-wrapper {
    .container {
      max-width: 100%;
      padding: 0px;
    }
  }

  .content-wrapper {
    flex: 1 0 auto;
    width: 100%;
    padding: 0 20px;

    @media #{$sm} {
      padding: 0 50px;
    }
  }
}
